import { Component, InputSignal, input } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { TravelerForm, TravelerProductForm } from 'src/app/types/traveler'
import { ProductService } from 'src/app/services/product.service'
import { TagService } from 'src/app/services/tag.service'
import { ShippingService } from 'src/app/services/shipping.service'
import { environment } from 'src/environments/environment'
import { PassportOptionComponent } from 'src/app/components/passport.option/passport.option.component'
import { GovFeeComponent } from '../gov.fee/gov.fee.component'
import { SelectPassportTypeComponent } from '../shared/select.passport.type/select.passport.type.component'

@Component({
	selector: 'gwc-ca-passport-type',
	standalone: true,
	templateUrl: './ca.passport.type.component.html',
	styleUrl: './ca.passport.type.component.scss',
	imports: [
		MatFormFieldModule,
		MatSelectModule,
		ReactiveFormsModule,
		MatRadioModule,
		MatRadioModule,
		PassportOptionComponent,
		GovFeeComponent,
		SelectPassportTypeComponent
	]
})

export class CaPassportTypeComponent {
	public traveler: InputSignal<FormGroup<TravelerForm>> = input.required<FormGroup<TravelerForm>>()
	public passport: InputSignal<FormGroup<TravelerProductForm>> = input.required<FormGroup<TravelerProductForm>>()
	public submitted: InputSignal<boolean> = input.required<boolean>()
	public domain: string = environment.source.domain

	constructor(
		private productService: ProductService,
		private tagService: TagService,
		private shippingService: ShippingService
	) { }

	public getProductDetails(traveler: FormGroup<TravelerForm>, passport: FormGroup<TravelerProductForm>, detail: string) {
		return this.productService.getProductDetails(traveler.value.info as any, passport.value as any, detail)
	}

	private checkShipping(traveler): void {
		this.shippingService.checkShipping(traveler.value, false)
	}

	public serviceUpdated(traveler, passport): void {
		this.checkShipping(traveler)
		const passport_value = passport.value
	
		try {
		  if (passport_value.product_uuid) {
			this.productService.getGAItem(traveler.value, passport_value)
			  .subscribe(response => {
				this.tagService.changeToCart(response, 'add_to_cart')
			  })
		  }
		} catch (exception) {}
	}

	public onApplyingFromChange(): void {
		const processingTypeValue = this.passport()?.controls.applying_from.value === 'US' ? 'regular' : null
		this.passport().controls.processing_type.setValue(processingTypeValue)
		this.passport().controls.processing_type.updateValueAndValidity()
	}
}

<p>{{ description }}</p>
<mat-radio-group color="primary"
  aria-label="Select Expediting Options"
  [formControl]="field"
  class="gw-checkout-radio"
  (change)="onShippingChanged($event)"
  [class.gw-checkout-radio--submitted]="submitted">
  @for (option of options; track option) {
    <section>
      <!-- @if (option.list) { -->
        <mat-radio-button color="primary"
          class="gw-chkout-radio__item"
          [value]="option.id">
          <span class="gw-chkout-shipping-option">
            <strong class="gw-chkout-shipping-option__name">
              {{ option.friendly_name }}
              @if (option.provider) {
                <img class="gwc-shipping-opt__logo"
                  [alt]="option.provider"
                  src="assets/svg/{{option.friendly_name.includes('Canada') ? 'canada_post' : option.provider}}.svg" />
              }
            </strong>
            <span class="gw-chkout-shipping-option__desc">
              @if (option.arrival_time) {
                Get it Next Day by {{ displayTime(option.arrival_time) | uppercase }}
              } @else {
                {{ option.description }}
              }
            </span>
            <strong class="gw-chkout-shipping-option__price">
              {{ option.cost/100 | currency:option.currency || 'USD':'symbol':'1.2-2' }}
            </strong>
          </span>
        </mat-radio-button>
      <!-- } -->
    </section>
  }
</mat-radio-group>

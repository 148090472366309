<section class="gw-idp-type">
	<div class="gw-product-type__section">
		<span class="gw-chkout-idp__description">
			The International Driving Permit (IDP) is a document that grants someone the privilege of driving a motor vehicle legally while abroad. It does not replace your government-issued U.S. driver’s license. Essentially, an IDP serves as a basic translation of your U.S. driver's license. It is translated in 10 languages and features your: Photo. Full name. Relevant driver information. Note: IDP is not available in digital form.
		</span>
	</div>
	<div class="gw-product-type__section">
		@if (!getProductDetails(traveler(), product(), 'product')?.meta?.hide_service || product().value.product_uuid) {
			<h3 class="gw-chkout-label">Select Service Option</h3>
			<mat-radio-group #radio
				aria-label="Select Expediting Options"
				[formControl]="product()?.controls.option_uuid"
				class="gw-chkout-radio"
				[class.gw-checkout-radio--submitted]="submitted()"
				(change)="serviceUpdated(traveler(), product())">
				@for (option of getProductDetails(traveler(), product(), 'services'); track option) {
					<mat-radio-button color="primary"
						class="gw-chkout-radio__item"
						[disabled]="option.sold_out"
						[value]="option.uuid">
						<gwc-idp-option [option]="option">
						</gwc-idp-option>
					</mat-radio-button>
				}
				<div class="gw-chkout__radio-message">
					Please select an expediting option.
				</div>
			</mat-radio-group>
		}
	</div>
</section>

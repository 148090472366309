import { Injectable } from '@angular/core'
import { Validators, AbstractControl, ValidationErrors, ValidatorFn, FormGroup, FormBuilder } from '@angular/forms'
import { validateDate } from '../validators/date.validator'
import { CommonService } from 'src/app/services/common.service'
import { FilterAttributeData } from '../types/products'
import { TravelerForm, TravelerInfoForm, TravelerProductForm } from '../types/traveler'
import { environment } from 'src/environments/environment'
import { DateTime } from 'luxon'

@Injectable({
  providedIn: 'root'
})

export class FormService {
  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService
  ) {}

  public getPassportProductRequest(): FilterAttributeData {
    return {
      type: 'passport',
      country: 'US',
      citizenship: 'US',
      residency: '', //'FL'
      residence_country: 'US',
    }
  }

  public getIDPProductRequest(): FilterAttributeData {
    return {
      type: 'idp',
      country: 'US',
      citizenship: 'US',
      residency: '', //'FL'
      residence_country: 'US',
    }
  }

  public getProductForm(type: string, od_print_location?: string): FormGroup<TravelerProductForm> {
    let product_form = this.formBuilder.group<TravelerProductForm>({
      type: this.formBuilder.control(type, Validators.required),
      country: this.formBuilder.control('', Validators.required),
      product_uuid: this.formBuilder.control('', Validators.required),
      option_uuid: this.formBuilder.control('', Validators.required),
      addons: this.formBuilder.control([]),
      subtype: this.formBuilder.control(''),
      initial: this.formBuilder.control(true)
    })

    if (type === 'digital-photo') {
      product_form.addControl('scanner_message_token', this.formBuilder.control(''))
      
      if (od_print_location) {
        product_form.addControl('od_print_location', this.formBuilder.control(''))
      }
    } else if (type === 'ca_passport') {
      product_form.addControl('processing_type', this.formBuilder.control('', Validators.required))
      product_form.addControl('applying_from', this.formBuilder.control(undefined, Validators.required))
    }

    return product_form
  }

  public getTravelerForm(): FormGroup<TravelerForm> {
    const travelerForm = this.formBuilder.group<TravelerForm>({
      info: this.formBuilder.group<TravelerInfoForm>({
        first_name: this.formBuilder.control('', Validators.required),
        last_name: this.formBuilder.control('', Validators.required),
        date_of_birth: this.formBuilder.control('', [
          Validators.required,
          Validators.minLength(8),
          validateDate('1900-01-01', DateTime.now().toFormat('yyyy-MM-dd'))
        ]),
        citizenship: this.formBuilder.control('', [
          Validators.required, 
          this.locationValidator('citizenship')
        ]),
        residency: this.formBuilder.control('', [
          this.locationValidator('residency')
        ]), //'FL'
        residence_country: this.formBuilder.control('US', Validators.required)
      }),
      products: this.formBuilder.array<FormGroup<TravelerProductForm>>([], Validators.required)
    })

    travelerForm.controls.info.patchValue({
      citizenship: environment.main_product?.citizenship || 'US',
      residence_country: environment.main_product?.residence_country || 'US'
    })

    if (environment.source.domain === 'idp') {
      travelerForm.controls.info.addControl('dl_zip_code', this.formBuilder.control(''))
    }

    return travelerForm
  }

  public locationValidator(type: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let location
      
      if (type === 'citizenship' || type === 'country') {
        location = this.commonService.getCountry(control.value)
      } else if (type === 'residency') {
        if (control.value && control.value.length > 0) {
          location = this.commonService.getState(control.value)

          if (!location) {
            //Revisit for visas that care about the residency
            control.patchValue('')
            return null
          }
        } else {
          return null
        }
      }

      return location ? null : { invalid: { value: control.value } }
    }
  }
}
